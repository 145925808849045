@import url('https://fonts.googleapis.com/css2?family=Andada+Pro&family=Quintessential&family=Tangerine&display=swap');

:root {
    --ck-color-dark-0: #0A121A;
    --ck-color-dark-1: #0E1924;
    --ck-color-dark-2: #1E354D;
    --ck-color-dark-3: #425266;
    --ck-color-dark-4: #2f455c;

    --ck-color-white-0: #FFFFFF;
    --ck-color-white-1: #FAFAFABF;
    --ck-color-white-2: #fbfaff85;

    --ck-color-primary-0: #723ED7;
    --ck-color-primary-0-h: 114, 62, 215;
    --ck-color-primary-1: #8E54C0;

    --ck-color-orange-0: #ff3600;
    --ck-color-orange-1: #ff4500;

    --ck-color-sky: #00bdff;

    --ck-opacity-9: .9;
    --ck-opacity-7: .7;
    --ck-opacity-5: .5;

    --ck-shadow-btn: -3px -3px 5px #2f455c, 3px 3px 5px #2f455c;

    --ck-shadow-sky: inset 0 2px 2px rgba(0, 0, 0, 0.0125), 0 0 5px rgba(34, 139, 230, 0.5);
    --ck-shadow-dark: inset 0 2px 2px rgba(0, 0, 0, 0.0125), 0 0 5px rgba(64, 81, 97, 0.5);

    --ck-lg-bg-0: linear-gradient(315deg, #0f1b27, #0d1720, #E78E28, #ca2929);
    --ck-lg-bg-1: linear-gradient(315deg, #0f1b27, #0d1720);
    --ck-lg-bg-2: linear-gradient(315deg, #0d1720, #0f1b27);

    --ck--shadow-1: -5px -5px 5px #060a0e, 5px 5px 5px #060a0e;
    --ck--shadow-in_0: inset -3px -3px 9px #060a0e, inset 3px 3px 9px #16283a;
    --ck--shadow-in_1: inset -5px -5px 4px #060a0e, inset 5px 5px 4px #16283a;


}

* {
    margin: 0;
    padding: 0;
    font-family: 'Andada Pro', serif;
}

html {
    scroll-behavior: smooth !important;
}

body {
    background: var(--ck-color-dark-0) !important;
    color: var(--ck-color-white-0) !important;
    font-family: 'Andada Pro', serif !important;
    transition: all .5s ease-in-out;
}

.warning {
    color: rgb(255, 96, 96) !important;
}

.btn:not(.left-nav_link .btn, .btn-sm) {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}


.btn-danger {
    background-color: #d32f2f !important;
}

.btn-outline-secondary {
    border-color: var(--ck-color-white-1);
    color: var(--ck-color-white-1);
}

.text-orange {
    color: var(--ck-color-orange-0) !important;
}


/* modal css */
.modal-content {
    background: var(--ck-color-dark-2) !important;
    color: var(--ck--color-white-0);
}

.modal-header,
.modal-footer {
    border-color: var(--ck-color-dark-1) !important;
}

/* card css */
.card {
    color: var(--ck--color-white-0);
    overflow: hidden;
}

.card,
.card-body {
    background: var(--ck-color-dark-2) !important;
    border: none !important;
    color: var(--ck--color-white-0) !important;
}

.card-header.bg,
.card-footer.bg {
    background: var(--ck-color-dark-1) !important;
}

.card:hover img {
    transition: all .5s ease-in-out; 
    transform: scale(1.1);
}

.btn-primary,
.btn-primary:disabled {
    background: var(--ck-color-orange-1);
    color: var(--ck--color-white-1);
    border: none;
}

.blog-card.card,
.blog-details-card .card {
    border: 1px solid var(--ck-color-dark-3) !important;
}

.blog-card.card .blog-card-img {
    border-right: 1px solid var(--ck-color-dark-3) !important;
}

.blog-card.card:hover {
    box-shadow: var(--ck-shadow-sky);
}

.blog-card.card,
.blog-card.card .card-body {
    background: none !important;
}
.blog-details-card .card-body{
    background: var(--ck-color-dark-0) !important;
}

.btn-primary:disabled {
    opacity: .2;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
    background-color: var(--ck-color-orange-0) !important;
    color: var(--ck--color-white-0);
}

/* forms */

form label,
.form-text {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

input,
select,
textarea {
    border: 1px solid var(--ck-color-dark-1) !important;
    position: relative;
    color: var(--ck-color-white-1) !important;
    background: var(--ck-color-dark-1) !important;
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
}

textarea::placeholder,
input::placeholder {
    color: var(--ck-color-white-2) !important;
}

input:hover,
input:active,
select:hover,
textarea:hover {
    background: var(--ck-color-dark-0) !important;

}

input:focus,
select:focus,
textarea:focus {
    background: var(--ck-color-dark-0) !important;
    box-shadow: var(--ck-shadow-sky) !important;
    outline: none;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

input:disabled,
select:disabled,
textarea:disabled,
input:read-only,
textarea:read-only {
    background: var(--ck-color-dark-3) !important;
}

.react-datepicker {
    background: var(--ck-color-dark-3) !important;
    border-color: var(--ck-color-dark-3) !important;
}

.react-datepicker__header {
    border-color: var(--ck-color-dark-3) !important;
    background: var(--ck-color-dark-2) !important;
}

.react-datepicker__day,
.react-datepicker__header * {
    color: var(--ck-color-white-0) !important;
}

.react-datepicker__day:hover {
    background: var(--ck-color-dark-2) !important;
    color: var(--ck-color-white-1);
}

.react-datepicker__day.react-datepicker__day--disabled:hover {
    background: var(--ck-color-dark-3) !important;
    color: var(--ck-color-white-2);
}

.react-datepicker__day.react-datepicker__day--disabled {
    color: var(--ck-color-white-2) !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    color: var(--ck-color-white-0) !important;
    background: var(--ck-color-dark-1);
}

.react-datepicker__year-dropdown {
    background: var(--ck-color-dark-2) !important;
    border-color: var(--ck-color-dark-3) !important;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:hover {
    background: var(--ck-color-dark-3) !important;
}

/* list group */

.list-group {
    border: none !important;
    transition: .5s all ease-in;
}

.list-group .list-group-item {
    background: var(--ck-color-dark-2);
    color: var(--ck-color-white-0) !important;
    border-color: var(--ck-color-dark-0);
    transition: .5s all ease-in;
}

.list-group .list-group-item.active {
    background: var(--ck-color-dark-0);
    border: 1px solid var(--ck-color-white-2);
    border-radius: 5px;
    border-top: none;
    border-right: none;
    border-left: none;
}

.list-group .list-group-item:hover,
.list-group .list-group-item-action:hover {
    background: var(--ck-color-dark-1);

}

.list-group-item-action:active {
    background: var(--ck-color-dark-0) !important;
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 1px !important;
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px;
}

.nav-tabs {
    border-bottom-color: var(--ck-color-dark-3);
}

.nav-tabs .nav-link {
    border-color: var(--ck-color-dark-0);
    background: var(--ck-color-dark-3);
}

.nav-tabs a {
    color: var(--ck-color-white-1) !important;
}

.nav-tabs .nav-link:hover {
    background: var(--ck-color-dark-1);
    border-color: var(--ck-color-dark-0) !important;
}

.nav-tabs .nav-link.active {
    border-color: var(--ck-color-dark-0) !important;
    background: var(--ck-color-dark-0) !important;
    color: var(--ck-color-white-0) !important;
}


.profile-d .rounded-circle {
    border: solid 2px transparent;
    background-image: linear-gradient(var(--ck-color-dark-1), var(--ck-color-dark-1)), radial-gradient(circle at top left, #ff3600, #723ED7, #ff4500);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: inline-block;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}


.bg-cover {
    display: block;
    width: 100%;
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

/* Table */

tr th,
tr td {
    text-wrap: nowrap !important;
}

table> :not(caption, thead)>*>* {
    background: var(--ck-color-dark-2) !important;
    color: var(--ck-color-white-0) !important;
    border-bottom-color: var(--ck-color-dark-1) !important;
}

thead>*>* {
    background: var(--ck-color-dark-1) !important;
    color: var(--ck-color-white-1) !important;
}

table tbody tr:nth-child(odd)>* {
    background: var(--ck-color-dark-2) !important;
}

.table-bordered> :not(caption)>*>*,
.table-bordered> :not(caption)>* {
    border-color: var(--ck-color-dark-3);
}

.table>tbody>tr:hover>* {
    --bs-table-bg-state: var(--ck-color-dark-4) !important;
}

.blog-card .blog-card-img {
    height: 200px;
}


@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .blog-card .blog-card-img {
        width: 40%;
        height: 200px;
    }

    .blog-card .card-body {
        width: 60%;
    }
}